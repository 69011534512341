import PropTypes from 'prop-types';
import React from 'react';

const Footer = ({ location }) => {
	return (
		<footer className="m-5 text-center text-muted">
			<div className="container">
				<p>© {new Date().getFullYear()}</p>

				{location.pathname === '/' && <h1 className="small">Denizko</h1>}
				{location.pathname !== '/' && <p className="small">Denizko</p>}
			</div>
		</footer>
	);
};

Footer.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}),
};

export default Footer;
