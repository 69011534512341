import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Logo from '../../images/framework/logo.svg';

function SEO({
	description = '',
	id = null,
	image = null,
	lang = 'en',
	location = null,
	meta = [],
	published_time,
	tags = '',
	title = '',
	type = 'website',
}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						author
						copyrightYear
						description
						siteUrl
						title
					}
				}
			}
		`
	);

	const imageUrl = image ? `${site.siteMetadata.siteUrl}${image}` : '';
	const logoUrl = `${site.siteMetadata.siteUrl}${Logo}`;
	const metaDescription = description || site.siteMetadata.description;
	const pageUrl = `${site.siteMetadata.siteUrl}${location.pathname || ''}`;

	let schemaArticle = null;
	let schemaProduct = null;
	const schemaOrganization = {
		'@context': 'https://schema.org',
		'@type': 'Organization',
		name: site.siteMetadata.title,
		url: site.siteMetadata.siteUrl,
		logo: logoUrl,
		sameAs: [
			'https://www.instagram.com/denizko.o/',
			'https://www.youtube.com/channel/UCKXKL3DScrJTTyIyftgO9DQ',
			'https://www.pinterest.se/denizketo/',
		],
	};

	let metaTags = [
		{
			name: 'description',
			content: metaDescription,
		},
		{
			name: 'keywords',
			content: tags,
		},
		{
			property: 'og:title',
			content: title,
		},
		{
			property: 'og:description',
			content: metaDescription,
		},
		{
			property: 'og:image',
			content: imageUrl,
		},
		{
			property: 'og:site_name',
			content: site.siteMetadata.title,
		},
		{
			property: 'og:type',
			content: type,
		},
		{
			property: 'og:url',
			content: pageUrl,
		},
		{
			name: 'twitter:card',
			content: 'summary',
		},
		{
			name: 'twitter:creator',
			content: site.siteMetadata.author,
		},
		{
			name: 'twitter:description',
			content: metaDescription,
		},
		{
			name: 'twitter:image',
			content: imageUrl,
		},
		{
			name: 'twitter:title',
			content: title,
		},
	].concat(meta);

	if (type === 'article') {
		schemaArticle = {
			'@context': 'https://schema.org',
			'@type': 'BlogPosting',
			mainEntityOfPage: {
				'@type': 'WebPage',
				'@id': pageUrl,
			},
			headline: title,
			image: imageUrl,
			author: {
				'@type': 'Person',
				name: 'Deniz',
			},
			publisher: {
				'@type': 'Organization',
				name: 'Deniz',
				logo: {
					'@type': 'ImageObject',
					url: logoUrl,
				},
			},
			dateModified: published_time,
			datePublished: published_time,
		};

		metaTags = [
			...metaTags,
			{
				name: 'article:author',
				content: site.siteMetadata.author,
			},
			{
				name: 'article:published_time',
				content: published_time,
			},
		].concat(meta);

		if (tags) {
			const tagList = tags.split(',').map((value) => {
				return {
					name: 'article:tag',
					content: value.trim(),
				};
			});

			metaTags = [...metaTags, ...tagList];
		}
	} else if (type === 'product') {
		schemaProduct = {
			'@context': 'https://schema.org',
			'@type': 'Product',
			name: title,
			description: description,
			brand: {
				'@type': 'Brand',
				name: 'Denizko',
			},
			image: imageUrl,
			offers: {
				'@type': 'Offer',
				availability: 'https://schema.org/InStock',
				price: '20',
				priceCurrency: 'EUR',
				priceValidUntil: '2025-06-20',
				url: pageUrl,
			},
			sku: id,
		};

		metaTags = [
			...metaTags,
			{
				name: 'og:availability',
				content: 'instock',
			},
			{
				name: 'og:brand',
				content: 'Denizko',
			},
			{
				name: 'product:price:amount',
				content: '0',
			},
			{
				name: 'product:price:currency',
				content: 'EUR',
			},
		].concat(meta);
	}

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={metaTags}
		>
			<script type="application/ld+json">
				{JSON.stringify(schemaOrganization)}
			</script>
			{schemaArticle && (
				<script type="application/ld+json">
					{JSON.stringify(schemaArticle)}
				</script>
			)}
			{schemaProduct && (
				<script type="application/ld+json">
					{JSON.stringify(schemaProduct)}
				</script>
			)}
		</Helmet>
	);
}

SEO.propTypes = {
	description: PropTypes.string,
	id: PropTypes.string,
	image: PropTypes.string,
	lang: PropTypes.string,
	location: PropTypes.shape({ pathname: PropTypes.string }),
	meta: PropTypes.array,
	published_time: PropTypes.string,
	tags: PropTypes.string,
	title: PropTypes.string.isRequired,
	type: PropTypes.string,
};

export default SEO;
