import PropTypes from 'prop-types';
import React from 'react';

import Footer from './footer';
import Header from './header';
import Menu from './menu';

const Layout = ({ children, header, location }) => {
	return (
		<>
			<Menu location={location} />
			{header && <Header header={header} location={location} />}
			{children}
			<Footer location={location} />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.array,
	header: PropTypes.shape({
		image: PropTypes.string,
		subTitle: PropTypes.string,
		title: PropTypes.string,
	}),
	location: PropTypes.object.isRequired,
};

export default Layout;
