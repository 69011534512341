import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Instagram from '../../images/icons/instagram.svg';
import Logo from '../../images/framework/logo.svg';
import Pinterest from '../../images/icons/pinterest.svg';
import Shop from '../../images/icons/shop.svg';
import YouTube from '../../images/icons/youtube.svg';

const Menu = ({ location }) => {
	const [toggle, setToggle] = useState(false);

	return (
		<>
			<nav className="fixed-top navbar navbar-expand-lg navbar-light">
				<Link
					className={`navbar-brand ${
						location.pathname !== '/' ? '' : 'invisible'
					}`}
					to="/"
					title="Denizko Logo"
				>
					<img alt="Denizko Logo" src={Logo} />
				</Link>

				<button
					aria-label="Menu"
					className="navbar-toggler"
					onClick={() => setToggle(!toggle)}
					type="button"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div
					className={`${
						toggle ? 'collapsed' : 'collapse'
					} justify-content-end navbar-collapse`}
				>
					<ul className="navbar-nav text-right">
						<li className="nav-item">
							<a
								className="nav-link"
								href="https://www.instagram.com/denizko.o/"
								title="Denizko Instagram"
							>
								<img
									alt="Denizko Instagram"
									className="icon img-grayscale"
									src={Instagram}
								/>
							</a>
						</li>
						<li className="nav-item">
							<a
								className="nav-link"
								href="https://www.pinterest.se/denizketo/"
								title="Denizko Pinterest"
							>
								<img
									alt="Denizko Pinterest"
									className="icon img-grayscale"
									src={Pinterest}
								/>
							</a>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/shop" title="Shop">
								<img
									alt="Denizko Merch Shop"
									className="icon img-grayscale"
									src={Shop}
								/>
							</Link>
						</li>
						<li className="nav-item">
							<a
								className="nav-link"
								href="https://www.youtube.com/channel/UCKXKL3DScrJTTyIyftgO9DQ"
								title="Denizko YouTube"
							>
								<img
									alt="Denizko YouTube"
									className="icon img-grayscale"
									src={YouTube}
								/>
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</>
	);
};

Menu.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}),
};

export default Menu;
