import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import React from 'react';

import Logo from '../../images/framework/logo.svg';

const Header = ({ header, location }) => {
	return (
		<>
			<div className="bg-transparent jumbotron jumbotron-fluid position-relative">
				{header && header.image && (
					<div
						className="jumbotron-background"
						style={{ backgroundImage: `url(${header.image})` }}
					></div>
				)}

				<div
					className={`align-items-center no-gutters row ${
						header.class ? header.class : 'h-50'
					}`}
				>
					<div className="col">
						{location.pathname === '/' && (
							<div className="blur-background p-1 text-center w-100">
								<img
									alt="Denizko.o Logo"
									src={Logo}
									style={{ width: '300px' }}
								/>
							</div>
						)}

						{header && (header.title || header.subTitle) && (
							<Fade up distance={'10%'}>
								<div className="blur-background p-1 text-dark text-center w-100">
									{header && header.title && (
										<h1 className="display-3 font-weight-lighter">
											{header.title}
										</h1>
									)}
									{header && header.subTitle && (
										<p className="small">{header.subTitle}</p>
									)}
								</div>
							</Fade>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

Header.propTypes = {
	header: PropTypes.shape({
		class: PropTypes.string,
		image: PropTypes.string,
		subTitle: PropTypes.string,
		title: PropTypes.string,
	}),
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}),
};

export default Header;
